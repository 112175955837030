var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","elevation":"0","height":"100%","min-height":"100%"}},[_c('v-form',{model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-card-title',{staticClass:"header mb-2"},[(_vm.parameter.id != -1)?_c('span',[_vm._v(_vm._s(_vm.user.firstName + " " + _vm.user.lastName))]):_vm._e(),(_vm.parameter.id == -1)?_c('span',[_vm._v(_vm._s(_vm.$t('user.dialog.addNewUser')))]):_vm._e()]),_c('v-card-text',{staticClass:"pb-0"},[_c('v-text-field',{attrs:{"label":_vm.$t('user.form.fields.firstName'),"required":"","rules":[
          function () { return !!_vm.user.firstName || _vm.$t('user.form.errors.firstNameRequired'); } ]},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}}),_c('v-text-field',{attrs:{"label":_vm.$t('user.form.fields.lastName'),"required":"","rules":[
          function () { return !!_vm.user.lastName || _vm.$t('user.form.errors.lastNameRequired'); } ]},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}}),_c('v-text-field',{attrs:{"label":_vm.$t('user.form.fields.email'),"required":"","rules":[
          function () { return !!_vm.user.email || _vm.$t('user.form.errors.emailRequired'); },
          !_vm.emailExists || _vm.$t('user.form.errors.emailAlreadyExists') ]},on:{"keydown":function($event){return _vm.verifyEmailUniqueness()}},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('v-select',{attrs:{"items":_vm.roles,"label":_vm.$t('user.form.fields.role'),"required":"","item-text":"name","item-value":"id","rules":[
          function () { return !!_vm.user.userRoleId || _vm.$t('user.form.errors.roleRequired'); } ]},model:{value:(_vm.user.userRoleId),callback:function ($$v) {_vm.$set(_vm.user, "userRoleId", $$v)},expression:"user.userRoleId"}}),(_vm.user.userRoleId == 2)?_c('v-select',{attrs:{"items":_vm.htcs,"label":_vm.$t('user.form.fields.affiliatedHtc'),"item-text":"name","item-value":"id","rules":[
          function () { return (_vm.user.userRoleId == 2 && !!_vm.user.htcId) ||
            _vm.$t('user.form.errors.htcNeeded'); } ]},model:{value:(_vm.user.htcId),callback:function ($$v) {_vm.$set(_vm.user, "htcId", $$v)},expression:"user.htcId"}}):_vm._e(),_c('v-switch',{attrs:{"label":_vm.$t('user.form.fields.isActive')},model:{value:(_vm.user.isActive),callback:function ($$v) {_vm.$set(_vm.user, "isActive", $$v)},expression:"user.isActive"}})],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end pt-0 mt-0 pb-3"},[_c('v-btn',{attrs:{"outlined":"","elevation":"0","color":"green","disabled":!_vm.isValid},on:{"click":function($event){return _vm.save()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.$t("actions.save"))+" ")],1),_c('v-btn',{attrs:{"outlined":"","elevation":"0","color":"red"},on:{"click":function($event){return _vm.closeModal()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-backup-restore")]),_vm._v(" "+_vm._s(_vm.$t("actions.cancel"))+" ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }