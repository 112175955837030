<template>
  <v-card tile elevation="0" height="100%" min-height="100%">
    <v-form v-model="isValid">
      <v-card-title class="header mb-2">
        <span v-if="parameter.id != -1">{{
          user.firstName + " " + user.lastName
        }}</span>
        <span v-if="parameter.id == -1">{{
          $t('user.dialog.addNewUser')
        }}</span>        
      </v-card-title>
      <v-card-text class="pb-0">
        <v-text-field
          :label="$t('user.form.fields.firstName')"
          required
          v-model="user.firstName"
          :rules="[
            () => !!user.firstName || $t('user.form.errors.firstNameRequired'),
          ]"
        ></v-text-field>

        <v-text-field
          :label="$t('user.form.fields.lastName')"
          required
          v-model="user.lastName"
          :rules="[
            () => !!user.lastName || $t('user.form.errors.lastNameRequired'),
          ]"
        ></v-text-field>

        <v-text-field
          :label="$t('user.form.fields.email')"
          required
          v-model="user.email"
          :rules="[
            () => !!user.email || $t('user.form.errors.emailRequired'),
            !emailExists || $t('user.form.errors.emailAlreadyExists'),
          ]"
          @keydown="verifyEmailUniqueness()"
        ></v-text-field>
        <v-select
          :items="roles"
          :label="$t('user.form.fields.role')"
          required
          v-model="user.userRoleId"
          item-text="name"
          item-value="id"
          :rules="[
            () => !!user.userRoleId || $t('user.form.errors.roleRequired'),
          ]"
        ></v-select>
        <v-select
          v-if="user.userRoleId == 2"
          :items="htcs"
          :label="$t('user.form.fields.affiliatedHtc')"
          v-model="user.htcId"
          item-text="name"
          item-value="id"
          :rules="[
            () =>
              (user.userRoleId == 2 && !!user.htcId) ||
              $t('user.form.errors.htcNeeded'),
          ]"
        ></v-select>
        <v-switch
          v-model="user.isActive"
          :label="$t('user.form.fields.isActive')"
        ></v-switch>
      </v-card-text>
    </v-form>
    <v-card-actions class="d-flex justify-end pt-0 mt-0 pb-3">
      <v-btn
        outlined
        elevation="0"
        color="green"
        :disabled="!isValid"
        @click="save()"
      >
        <v-icon left>mdi-content-save</v-icon>
        {{ $t("actions.save") }}
      </v-btn>
      <v-btn
        outlined
        elevation="0"
        color="red"
        @click="closeModal()"
      >
        <v-icon left>mdi-backup-restore</v-icon>
        {{ $t("actions.cancel") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped>
  .header
  {
    background-color: rgb(0,130,237) !important;
    color: white;
  }
</style>

<script>

import axios from "axios";

export default {
  props: ["parameter"],
  data() {
    return {
      isValid: null,
      isEdit: false,
      user: { isActive: true, role: { id: null } },
      passwordValidationField: "",
      roles: [],
      validateUniquenessTimeout: null,
      emailExists: false,
      htcs: [],
    };
  },
  computed: {
    mappedUser() {
      return {
        id: this.user.id,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        email: this.user.email,
        isActive: this.user.isActive,
        userRoleId: this.user.userRoleId,
        htcId: this.user.htcId == null ? null : this.user.htcId,
      };
    },
  },
  methods: {
    save() {
      this.$store.dispatch("saveUser", this.mappedUser).catch((err) => {
        console.error(err);
      });
      this.closeModal();
    },
    closeModal() {
      this.$store.commit("setUsersKey");
      this.$store.commit("hideModal", null);
    },
    getRoles() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_URI}/role/`)
        .then((response) => {
          this.roles = response.data;
        })
        .catch((e) => {});
    },
    verifyEmailUniqueness() {
      if (this.validateUniquenessTimeout)
        clearTimeout(this.validateUniquenessTimeout);
      this.validateUniquenessTimeout = setTimeout(() => {
        this.emailExists = false;
        axios
          .post(`${process.env.VUE_APP_BACKEND_URI}/user/getbyemail/`, {
            email: this.user.email,
          })
          .then((response) => {
            if (response.data.id != null) {
              if (response.data.id != this.user.id) {
                this.emailExists = true;
              }
            }
          })
          .catch((e) => {});
      }, 500);
    },
    loadUser(id) {
      axios
        .get(`${process.env.VUE_APP_BACKEND_URI}/user/` + id)
        .then((response) => {
          this.user = response.data;
        })
        .catch((e) => {});
    },
    getHTCs() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_URI}/htc/`)
        .then((response) => {
          this.htcs = response.data;
        })
        .catch((e) => {});
    },
  },
  created() {
    this.getRoles();
    this.getHTCs();
    if (this.parameter.id) {
      this.loadUser(this.parameter.id);
      this.isEdit = true;
    }
  },
};
</script>